body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #e5e5e5;
  background-color: #fff;
}

.container {
  max-width: 1024px;
  width: 82%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.clipboard-show {
  position: absolute;
  width: 100%;
  background-color: green;
  display: flex;
  justify-content: center;
  height: 30px;
  align-items: center;
}

.clipboard-hidden {
  display: none;
}
