header {
  background-color: #5659c4;
}

header h1 {
  padding: 44px 0 22px;
  font-size: 30px;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  header h1 {
    text-align: left;
    font-size: 40px;
  }
}
