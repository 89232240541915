.loader {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
}

.loader img {
  width: 200px;
}

.loader svg path,
.loader svg rect {
  fill: #fff;
}
