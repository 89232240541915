.table {
  display: flex;
  padding-top: 60px;
  overflow-x: scroll;
  width: 100%;
}

.table-head,
.table-row {
  display: flex;
  padding: 16px 12px 16px;
  color: gray;
}

.table-row {
  flex-direction: column;
  border-bottom: 1px solid lightgray;
  border-top: 1px solid lightgray;
  border-right: 1px solid lightgray;
}

.table-head {
  flex-direction: column;
  background: #f2f2f2;
}

.table-head span:first-child,
.table-row li:first-child {
  margin-bottom: 24px;
  height: 40px;
}

.table-head span:nth-child(2),
.table-row li:nth-child(2) {
  margin-bottom: 24px;
  height: 40px;
}

@media only screen and (min-width: 768px) {
  .table {
    flex-direction: column;
  }

  .table-head {
    flex-direction: row;
  }

  .table-head,
  .table-row {
    padding: 16px 24px 16px;
  }

  .table-row {
    flex-direction: row;
  }
  .table-head span:last-child,
  .table-row span {
    padding-left: 24px;
    padding-right: 24px;
  }

  .table-head span:first-child,
  .table-row li:first-child {
    width: 40%;
    height: auto;
    margin-bottom: 0;
  }

  .table-head span:nth-child(2),
  .table-row li:nth-child(2) {
    width: 40%;
    flex-grow: 1;
    height: auto;
    margin-bottom: 0;
  }
  .table-row {
    border-top: none;
    border-right: none;
  }
}
