.input-bar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 44px;
}

.input-bar-container .input-bar {
  display: flex;
  flex-direction: column;
  flex: 1 60%;
  position: relative;
}

.input-bar-container input {
  padding: 10px;
  font-size: 18px;
  border: none;
}

.input-bar-container input:focus {
  outline: none;
}

.input-bar-container button {
  flex: 1 20%;
  margin-top: 24px;
  align-self: auto;
  padding: 10px;
  font-size: 18px;
  font-weight: 800;
  border: none;
  border-radius: 5px;
  color: #5659c4;
  background-color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s;
}

.input-bar-container button:hover {
  transform: translateX(-2px);
}

.input-bar-container button:focus {
  outline: none;
}

@media only screen and (min-width: 768px) {
  .input-bar-container {
    flex-direction: row;
  }

  .input-bar-container button {
    margin-top: 0;
    margin-left: 24px;
    align-self: flex-start;
  }
}
